

// Import Icon
import Iconclock from "../../assets/img/icon/clock.png"
import IconMap from "../../assets/img/icon/map.png"
import IconDate from "../../assets/img/icon/date.png"






export const EventsDatas = [
 
  {
    heading:"Run for the senior citizens.",
    category:"#FoodCamp",
    para:` Lorem ipsum dolor sit amet, consectetur notted adipisicing
    elit sed do eiusmod tempor. Lorem ipsum dolor sitr.`,
    IconClock:Iconclock,
    IconMap:IconMap,
    IconDate:IconDate
  },
  {
    heading:"Run for the senior citizens.",
    category:"#FoodCamp",
    para:` Lorem ipsum dolor sit amet, consectetur notted adipisicing
    elit sed do eiusmod tempor. Lorem ipsum dolor sitr.`,
    IconClock:Iconclock,
    IconMap:IconMap,
    IconDate:IconDate
  },
  {
    heading:"Run for the senior citizens.",
    category:"#FoodCamp",
    para:` Lorem ipsum dolor sit amet, consectetur notted adipisicing
    elit sed do eiusmod tempor. Lorem ipsum dolor sitr.`,
    IconClock:Iconclock,
    IconMap:IconMap,
    IconDate:IconDate
  }
   
]