// Item Img
import img1 from '../../../assets/img/causes/causes-1.png'
import img2 from '../../../assets/img/causes/causes-2.png'
import img3 from '../../../assets/img/causes/causes-3.png'

// Import Icon
import Icon from "../../../assets/img/icon/cal.png"
import Icon1 from "../../../assets/img/icon/user.png"





export const TrendingData = [
    {
        img: img1,
        category:"Food & water",
        goal:"$10,000",
        heading: "Collect fund for drinking water & healthy food",
        para:`Lorem ipsum dolor sit amet, consectetur notted
        adipisicing elit sed do eiusmod tempor.`,
        IconAdmin:Icon,
        DateIcon:Icon1
    },
    {
        img: img2,
        category:"Food & water",
        goal:"$10,000",
        heading: "Collect fund for drinking water & healthy food",
        para:`Lorem ipsum dolor sit amet, consectetur notted
        adipisicing elit sed do eiusmod tempor.`,
        IconAdmin:Icon,
        DateIcon:Icon1
    },
    {
        img: img3,
        category:"Food & water",
        goal:"$10,000",
        heading: "Collect fund for drinking water & healthy food",
        para:`Lorem ipsum dolor sit amet, consectetur notted
        adipisicing elit sed do eiusmod tempor.`,
        IconAdmin:Icon,
        DateIcon:Icon1
    },
   
]