// Import Img
import Img1 from "../../assets/img/volunteer/volunteer-1.png"
import Img2 from "../../assets/img/volunteer/volunteer-2.png"
import Img3 from "../../assets/img/volunteer/volunteer-3.png"
import Img4 from "../../assets/img/volunteer/volunteer-4.png"
import img5 from "../../assets/img/volunteer/volunteer-5.png"
import Img6 from "../../assets/img/volunteer/volunteer-6.png"
import Img7 from "../../assets/img/volunteer/volunteer-7.png"
import Img8 from "../../assets/img/volunteer/volunteer-8.png"

export const TeamDatas = [
 
    {
        img:Img1,
        name:"Mark carson",
        title:"Project manager"
    },
    {
        img:Img2,
        name:"Anna naomi",
        title:"Nutritionist"
    },
    {
        img:Img3,
        name:"Graim clair",
        title:"Health advisor"
    },
    {
        img:Img4,
        name:"David smith",
        title:"Teacher"
    },
    {
        img:img5,
        name:"Jason parker",
        title:"Manager"
    },
    {
        img:Img6,
        name:"Clair martha",
        title:"Team leader"
    },
    {
        img:Img7,
        name:"Tom latham",
        title:"Donor"
    },
    {
        img:Img8,
        name:"Alisa roselin",
        title:"Doctor"
    }

  ]

