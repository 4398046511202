import React from 'react'
// Import CommonBanner Area
import CommonBanner from '../component/Common/CommonBanner'

const BlogMainPage = () => {
  return (
    <>
        <CommonBanner heading="News" pagination="News"/>
    </>
    
  )
}

export default BlogMainPage