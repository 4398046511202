import React from 'react'
// Import CommonBanner Area
import CommonBanner from '../component/Common/CommonBanner'
// import Team
import TeamArea from '../component/Team'

const TeamPage = () => {
  return (
    <>
     <CommonBanner heading="Volunter" pagination="Volunter"/>
     <TeamArea/>

    </>
  )
}

export default TeamPage