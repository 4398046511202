// Import Img
import img1 from "../../assets/img/gallery/gallery-grid-1.png"
import img2 from "../../assets/img/gallery/gallery-grid-2.png"
import img3 from "../../assets/img/gallery/gallery-grid-3.png"
import img4 from "../../assets/img/gallery/gallery-grid-4.png"
import img5 from "../../assets/img/gallery/gallery-grid-5.png"
import img6 from "../../assets/img/gallery/gallery-grid-6.png"

// Import Icon
import Icon from "../../assets/img/icon/arrow-round.png"






export const GalleryData = [
 
  {
    img:img1,
    icon:Icon
  },
  {
    img:img2,
    icon:Icon
  },
  {
    img:img3,
    icon:Icon
  },
  {
    img:img4,
    icon:Icon
  },
  {
    img:img5,
    icon:Icon
  },
  {
    img:img6,
    icon:Icon
  },

   
]